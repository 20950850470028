const IMG_SWIPE_THRESH = 100; // really sensitive

const IMAGE_SIZES = {
  large: '(max-width: 768px) 100vw, (max-width: 1024px) 57vw, (max-width: 1440px) 60vw, 1684px',
  pdp: '(max-width: 768px) 100vw, (max-width: 1024px) 57vw, (max-width: 1440px) 30vw, 30vh',
  small:
    '(max-width: 414px) 90vw, (max-width: 549px) 90vw, (max-width: 768px) 46vw, (max-width: 860px) 46vw, (max-width: 1023px) 46vw, (max-width: 1279px) 30vw, (max-width: 1440px) 21vw, 21vw',
  largest: '(max-width: 768px) 100vw, (max-width: 1024px) 57vw, (max-width: 1440px) 60vw, 1684px',
  review: '(max-width: 768px) 88vw, (max-width: 1024px) 93vw, (max-width: 1440px) 52vw, 1574px',
};

const DOT_DIAMETER_LARGE = 8;
const DOT_DIAMETER_SMALL = 6;

const DEFAULT_TO_INDEX_DOTS = 2;

export {
  IMAGE_SIZES,
  IMG_SWIPE_THRESH,
  DOT_DIAMETER_LARGE,
  DOT_DIAMETER_SMALL,
  DEFAULT_TO_INDEX_DOTS,
};
