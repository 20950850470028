import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { finCalcInteractedWithAtom } from '@fincalc/Atoms/FinCalcInteractedWith.atom';
import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { atom } from 'jotai';

import { postcodeAtom } from 'Atoms/Search/PostcodeAtom/PostcodeAtom';
import { InputItem } from 'Components/FormFields/FormFields.entity';
import { leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';

import { leadFormIdAtom } from './LeadFormId.atom';

export const leadFormFieldsAtom = atom<InputItem[]>(get => {
  const flags = get(flagsAtom);
  const vehicle = get(currentVehicleAtom)!;
  const formId = get(leadFormIdAtom);
  const values = get(formStateAtom);
  const locale = get(localeAtom);
  const postcode = get(postcodeAtom);
  const isFinCalcInteractedWith = get(finCalcInteractedWithAtom);

  const isEcom = !!vehicle?.ecom?.isEcom;
  const extraData = { postcode: postcode[locale] };

  if (!formId) return [];

  // @ts-expect-error - fieldItems is optional
  const fields: InputItem[] = leadCtasConfig[formId].fieldItems?.({ flags, vehicle, isEcom }) ?? [];

  const activeFields = fields.filter(item => {
    return !!item.isActive?.({
      flags,
      vehicle,
      values,
      extraData,
      isFinCalcInteractedWith,
      locale,
    });
  });

  return activeFields;
});
