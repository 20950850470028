'use client';

import { HeycarImage } from '@core/Components/HeycarImage/HeycarImage';
import { kontentLoader } from '@core/Components/KenticoImage/KontentLoader';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { VehicleImage } from '@core/Entities/Vehicle/VehicleImage.entity';
import { Image as Pic } from '@gds/Icons/Paths/Image';
import Image from 'next/image';

import { heycarLoader } from 'Components/ClientWrappers/HeycarLoader';
import { IMAGE_SIZES } from 'Components/ImageCarousel/ImageCarousel.constants';

import RacLogo from '../../../../../web/public/img/rac.webp';

import styles from './HeroImage.module.css';

const RAC_IMG_SIZE = 70;

export const HeroImage = ({
  image,
  index,
  isRacApproved,
  totalImages,
  makeModelLabel,
  locale,
}: {
  image: VehicleImage;
  index?: number;
  isRacApproved?: boolean;
  totalImages?: number;
  makeModelLabel: string;
  locale: Locale;
}) => {
  const mainImgString = locale === 'uk' ? 'Main listing image' : "Image de l'annonce principale";
  const loader = image.isKontent ? kontentLoader : heycarLoader;
  return (
    <figure className={styles.wrpr}>
      <HeycarImage
        src={image?.url}
        alt={image?.altText || `${mainImgString} - ${makeModelLabel}`}
        className={styles.img}
        sizes={IMAGE_SIZES.small}
        loader={loader}
        forceVisible={(index ?? 100) <= 1}
        preload
      />

      {isRacApproved && (
        <aside className={styles.racBadge}>
          <Image src={RacLogo} width={RAC_IMG_SIZE} height={RAC_IMG_SIZE} alt="rac approved logo" />
        </aside>
      )}

      {totalImages && (
        <aside className={styles.imgsNum}>
          <Pic />
          {totalImages}
        </aside>
      )}
    </figure>
  );
};
