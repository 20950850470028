/* eslint-disable @typescript-eslint/no-extra-non-null-assertion */
/* eslint-disable @typescript-eslint/naming-convention */

import { preApprovalLeadIdAtom } from '@core/Atoms/Leads/PreApprovalLeadIs.atom';
import { localeAtom } from '@core/Atoms/Locale/Locale.atom';
import { formStateAtom } from '@core/Atoms/Pdp/FormState.atom';
import { searchParamsAtom } from '@core/Atoms/SearchParams/SearchParams.atom';
import { tenantAtom } from '@core/Atoms/Tenant/Tenant.atom';
import { currentVehicleAtom } from '@core/Atoms/Vehicle/CurrentVehicle.atom';
import { currentVehicleIdAtom } from '@core/Atoms/Vehicle/CurrentVehicleId.atom';
import { generateLeadTrackingData } from '@core/Tracking/LeadForms.tracking';

import { mapSelectedProductToLeadSubtype } from '@core/Utils/Leads/MapSelectedProductToLeadSubtype';
import { calculationAtom } from '@fincalc/Atoms/Calculation.atom';
import { finCalcInteractedWithAtom } from '@fincalc/Atoms/FinCalcInteractedWith.atom';
import { selectedProductIdAtom } from '@fincalc/Atoms/SelectedProductId.atom';

import { flagsAtom } from '@growthBookExperimentation/Atoms/Flags.atom';
import { trackCustomEvent } from '@tracking/Utils/TrackCustomEvent';
import { atom } from 'jotai';

import { leadFormIdAtom } from 'Atoms/App/Pdp/LeadFormId.atom';
import { leadFormStringsAtom } from 'Atoms/App/Pdp/LeadFormStrings.atom';

import { userIdAtom } from 'Atoms/User/UserId.atom';
import { LeadType } from 'Components/Pdp/LeadCTAs/LeadCTAs.entity';
import { leadCtasConfig } from 'Components/Pdp/LeadCTAs/LeadCtaConfig';

import { buildLeadPayload } from '../../../Components/LeadForms/Utils/BuildLeadPayload';
import { postLead } from '../../../Components/LeadForms/Utils/PostLead';
import { valuationAtom } from '../Valuation/Valuation.atom';

import { shouldShowLoaderAtom } from './LoadingState.atom';
import { openErrorModalAtom } from './OpenErrorModal.atom';
import { openLoadingModalAtom } from './OpenLoadingModal.atom';
import { openSuccessModalAtom } from './OpenSuccessModal.atom';
import { similarVehiclesAtom } from './SimilarVehicles.atom';
import { shouldShowFinanceQuoteAtom } from './shouldShowFinanceQuote.atom';

export const leadFormSubmitAtom = atom(null, async (get, set, source: string) => {
  const tenant = get(tenantAtom);
  const locale = get(localeAtom);
  const strings = get(leadFormStringsAtom);
  const formState = get(formStateAtom);
  const valuation = get(valuationAtom);
  const vehicle = get(currentVehicleAtom)!;
  const leadFormId = get(leadFormIdAtom) as LeadType;
  const similarVehicles = get(similarVehiclesAtom);
  const calculation = get(calculationAtom);
  const listingId = get(currentVehicleIdAtom);
  const searchParams = get(searchParamsAtom);
  const financeQuoteShowing = get(shouldShowFinanceQuoteAtom);
  const selectedProductId = get(selectedProductIdAtom);
  const flags = get(flagsAtom);
  const isFinCalcInteractedWith = get(finCalcInteractedWithAtom);
  const subtype = leadCtasConfig[leadFormId].leadSubtype?.(formState, { calculation });
  const header = leadCtasConfig[leadFormId].successModalHeader?.(strings);
  const isLeasing = mapSelectedProductToLeadSubtype(selectedProductId) === 'leasing';
  const leadFormType = locale === 'fr' && isLeasing ? 'leasing_quote' : 'finance_quote';
  const eligibleProducts = vehicle?.eligibleProducts;
  const isCaptiveId = calculation?.captiveId;
  const isLenderId = calculation?.lender;
  const lenderCaptiveId = locale === 'fr' ? isCaptiveId : isLenderId;

  const captiveId = financeQuoteShowing ? lenderCaptiveId : undefined;

  const shouldSetPreApprovalLeadId =
    locale === 'fr' &&
    flags.financePreApproval &&
    eligibleProducts?.includes('PREAPPROVAL_ANY_RCI') &&
    isFinCalcInteractedWith;

  set(openLoadingModalAtom, { ...(header && { header }) });
  let body;

  const fullNameParts = formState?.fullName?.split(' ');

  const formStateWithName = formState?.fullName
    ? { ...formState, firstName: fullNameParts[0], lastName: fullNameParts.slice(1).join(' ') }
    : formState;

  const kwankoTrackingIdMapping = {
    'request-video': 'G5132071013',
    'request-images': 'G5132071013',
    'book-appointment': 'G5132071013',
    'request-callback': 'G513207101F',
    'message-dealer': 'G5132071013',
    'message-dealer-finance': 'G51320710219',
    'call-dealer': 'G5132071017',
    'history-check': 'G5132071013',
    'leasing-online': '',
  };

  const kwankoTrackingId = kwankoTrackingIdMapping[leadFormId] ?? '';

  try {
    body = buildLeadPayload({
      values: formStateWithName,
      vehicle: vehicle,
      subtype,
      source,
      tenant,
      valuation,
      similarVehicles,
      calculation,
      financeQuoteShowing,
      leadId: leadFormId,
    });
  } catch (error) {
    console.error('Error occured when building payload', error);
    set(shouldShowLoaderAtom, false);
    return set(openErrorModalAtom, { ...(header && { header }) });
  }

  const utmParams = {
    utmSource: searchParams?.utm_source,
    utmMedium: searchParams?.utm_medium,
    utmCampaign: searchParams?.utm_campaign,
  };

  const leadResponse: any = await postLead(locale, body, utmParams);
  if (!leadResponse || !leadResponse?.lead_id) {
    set(shouldShowLoaderAtom, false);
    return set(openErrorModalAtom, { ...(header && { header }) });
  }

  const { lead_id: leadId, user_id: userId } = leadResponse;
  set(userIdAtom, userId); // update the global userId Atom
  if (shouldSetPreApprovalLeadId) set(preApprovalLeadIdAtom, leadId);
  const {
    leadSubmit: { event, context },
  } = generateLeadTrackingData({
    searchParams,
    userId,
    formState: formStateWithName,
    leadId,
    leadFormId: financeQuoteShowing ? leadFormType : leadFormId,
    listingId,
    captiveId: captiveId,
    source,
    vehicle,
    ctaLocation: '',
    leadAction: 'submit',
    hasOneClick: flags.leadFormOneClickChkbox,
    numSimilarVehicles: similarVehicles?.length,
  });
  trackCustomEvent({ event, context });
  set(shouldShowLoaderAtom, false);
  set(openSuccessModalAtom, {
    ...(header && { header }),
    kwankoTrackingId,
    kwankoListingId: leadId,
  });
});
