import { atom } from 'jotai';

import Cookies from 'js-cookie';

export const isMobileAppAtom = atom(() => {
  const activeServiceConsumer = Cookies.get('activeServiceConsumer');
  const isMobileApp = activeServiceConsumer === 'native';

  return isMobileApp;
});
